import React from 'react';
import PropTypes from 'prop-types';
import { animated, useSpring } from 'react-spring';

import { AnimatedSvg } from '@components/AnimatedSvg';

import { useObserver } from '@utils/hooks/useObserver';
import plusIcon from '@images/services/plus.svg';

import styles from './style.module.scss';

export const ServiceItem = ({ icon, title, content, style }) => {
  const [ref, isVisible] = useObserver({ threshold: 0.2 });

  const fadeIn = useSpring({ from: { opacity: 0 }, to: { opacity: isVisible ? 1 : 0 }, config: { duration: 800 } });
  return (
    <animated.div ref={ref} className={styles.root} style={fadeIn}>
      <AnimatedSvg Icon={icon}></AnimatedSvg>
      <h4 className={styles.title} style={style?.title}>
        {title}
      </h4>
      <p className={styles.content} style={style?.content}>
        {content}
      </p>
      <div className={styles.plus}>
        <img src={plusIcon} />
      </div>
    </animated.div>
  );
};

ServiceItem.propTypes = {
  icon: PropTypes.func.isRequired,
  title: PropTypes.string,
  content: PropTypes.string,
  style: PropTypes.shape({
    content: PropTypes.shape({
      maxWidth: PropTypes.string
    })
  })
};
