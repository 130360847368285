import React from 'react';
import PropTypes from 'prop-types';
import { animated } from 'react-spring';

import styles from './style.module.scss';

import { AnimatedSvg } from '@components/AnimatedSvg';

import { useObserver } from '@utils/hooks/useObserver';
import { useMobileFadeIn } from '@utils/hooks/useAnimation';

export const Reason = ({ reason: { title, icon } }) => {
  const [ref, isVisible] = useObserver({ threshold: 0.5 });
  const fadeIn = useMobileFadeIn(isVisible, 0);

  return (
    <animated.div key={title} className={styles.item} ref={ref} style={fadeIn}>
      <AnimatedSvg Icon={icon} />
      <h1 className={styles.itemTitle}>{title}</h1>
    </animated.div>
  );
};

Reason.propTypes = {
  reason: PropTypes.shape({
    title: PropTypes.string.isRequired,
    icon: PropTypes.func.isRequired
  }).isRequired
};
