import React from 'react';

import { ContactModal } from '@components/ContactModal';
import Office from '@images/office.jpg';
import { animated } from 'react-spring';
import { Background } from '@components/Background';
import { HeaderTitle } from '@components/HeaderTitle';
import { Image } from '@components/Image';
import { AnimatedText } from '@components/AnimatedText';

import { COLORS } from '@constants';

import { useObserver } from '@utils/hooks/useObserver';

import styles from './style.module.scss';

import { useMobileFadeIn } from '@utils/hooks/useAnimation';

export const Intro = () => {
  const [topRef, isTopVisible] = useObserver({ threshold: 0.7 });

  const fadeIn = useMobileFadeIn(isTopVisible, 0);

  return (
    <Background bg={COLORS.transparent}>
      <div id="home" className={styles.wrapper}>
        <div className={styles.left} ref={topRef}>
          <HeaderTitle color="#fff">
            <div className={styles.header}>
              You Tell The Story,
              <br />
              We Add The Expertise
            </div>
          </HeaderTitle>

          <animated.div style={fadeIn}>
            <p className={styles.paragraph}>
              <AnimatedText>
                Esportainment Media - your tech destination for high-level tasks of various complexity.
              </AnimatedText>
            </p>
            <p className={`${styles.paragraph} ${styles.mdContent}`}>
              <AnimatedText>
                We are not about just coding! We are keen on ready-to-earn products and services, from idea to its
                implementation and promotion.
              </AnimatedText>
            </p>
            <div className={styles.modal}>
              <ContactModal />
            </div>
          </animated.div>
        </div>
        <div className={styles.imageWrapper}>
          <Image alt="office" src={Office} delay={1000} />
        </div>
      </div>
    </Background>
  );
};
