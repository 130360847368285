import React from 'react';
import { animated } from 'react-spring';
import PropTypes from 'prop-types';

import styles from './style.module.scss';

import { useObserver } from '@utils/hooks/useObserver';

import { useMobileAppearLeft } from '@utils/hooks/useAnimation';

export const Image = ({ src, alt, delay = 1000 }) => {
  const [ref, isVisible] = useObserver({ threshold: 0.3 });

  const appearLeft = useMobileAppearLeft(isVisible, delay);

  return (
    <div className={styles.wrapper} ref={ref}>
      <animated.img className={styles.image} style={appearLeft} alt={alt} src={src} />
    </div>
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  delay: PropTypes.number.isRequired
};
