import audit from '@images/componentSvg/services/audit.svg';
import game from '@images/componentSvg/services/game.svg';
import design from '@images/componentSvg/services/design.svg';
import media from '@images/componentSvg/services/media.svg';
import marketing from '@images/componentSvg/services/marketing.svg';

import create from '@images/componentSvg/why/create.svg';
import grow from '@images/componentSvg/why/grow.svg';
import transparency from '@images/componentSvg/why/transparency.svg';
import deliver from '@images/componentSvg/why/deliver.svg';

export const footerLinks = [
  {
    label: 'Facebook',
    short: 'Fb',
    url: 'https://www.facebook.com/jmindsystems/'
  },
  {
    label: 'Linkedin',
    short: 'in',
    url: 'https://www.linkedin.com/company/jmind'
  },
  {
    label: 'Clutch',
    short: 'Cl',
    url: 'https://clutch.co/profile/jmindsystems'
  }
];

export const services = [
  {
    icon: audit,
    title: 'IT audit & consulting',
    content: 'Help you find the most efficient solution or cost-effective alternative'
  },
  {
    icon: game,
    title: 'Game development',
    content: 'We include game design services, animations, 2D artwork or 3D modeling, game UI/UX, front-end and ' +
      'back-end programming.'
  },
  {
    icon: design,
    title: 'Design',
    content: 'Your vision plus our expertise equals new impulse to your growth.'
  },
  {
    icon: media,
    title: `Media content`,
    content: 'It takes talented content marketers and a strategic B2B content creation plan to define that brand, ' +
      'distinguish it from the competition.',
  },
  {
    icon: marketing,
    title: 'Marketing Support for your Product',
    content: 'Help your product into the world with all the marketing tricks',
  }
];

export const references = [
  {
    image: require('@images/references/weplay.png'),
    name: 'WePlay Esports',
    position: '',
    content: 'WePlay Esports is a leading esports media holding with key offices in the USA, China, and Ukraine. The holding combines the best practices of esports, media, and entertainment fields to provide cutting-edge products and services worldwide.'
  },
  {
    image: require('@images/references/saptage.png'),
    name: 'saptage',
    position: '',
    content: 'Saptage is Media buro and Event Hub, specialized in film production, worldwide marketing research and solutions, media relations, above and below the line activities and events management.'
  },
  {
    image: require('@images/references/senseIT.png'),
    name: 'senseIT',
    position: '',
    content: 'Sense IT is created by experienced professionals constantly upgrading their expertise in the field of design, development, marketing, HR, and more.',
  },
  {
    image: require('@images/references/ENESTECH.png'),
    name: 'ENESTECH',
    position: '',
    content: 'ENESTECH company is a soft and hardware IT-developer for eSports business, headquartered in Kiev, Ukraine',
  },
  {
    image: require('@images/references/techiia.png'),
    name: 'techiia',
    position: '',
    content: 'TECHIIA is a holding that brings together the technological expertise of many companies and individual projects.'
  }
];

export const stack = [
  {
    name: 'kubernetes',
    image: require('@images/stack/kubernetes.svg'),
    width: '120px',
    height: '21px'
  },
  {
    name: 'aws',
    image: require('@images/stack/aws.svg'),
    width: '119px',
    height: '71px'
  },
  {
    name: 'google cloud',
    image: require('@images/stack/google_cloud.svg'),
    width: '120px',
    height: '116px'
  },
  {
    name: 'react',
    image: require('@images/stack/react.svg'),
    width: '120px',
    height: '45px'
  },
  {
    name: 'node',
    image: require('@images/stack/node_js.svg'),
    width: '120px',
    height: '74px'
  },
  {
    name: 'unreal',
    image: require('@images/stack/unreal_4.svg'),
    width: '120px',
    height: '46px'
  },
  {
    name: 'c++',
    image: require('@images/stack/c_plus.svg'),
    width: '120px',
    height: '135px'
  },
  {
    name: 'houdini',
    image: require('@images/stack/houdini.svg'),
    width: '120px',
    height: '20px'
  },
  {
    name: 'maya',
    image: require('@images/stack/maya.svg'),
    width: '120px',
    height: '40px'
  },
];

export const reasons = [
  {
    icon: create,
    title: 'We create value'
  },
  {
    icon: grow,
    title: 'You grow with us'
  },
  {
    icon: transparency,
    title: 'You get full transparency'
  },
  {
    icon: deliver,
    title: 'We deliver expertise'
  }
];
