import React from 'react';
import { animated } from 'react-spring';
import PropTypes from 'prop-types';
import PropTypesAirBnb from 'airbnb-prop-types';

import { useObserver } from '@utils/hooks/useObserver';
import { useMobileFadeIn } from '@utils/hooks/useAnimation';

export const AnimatedText = ({ children }) => {
  const [ref, isVisible] = useObserver({ threshold: 0.2 });
  const fadeIn = useMobileFadeIn(isVisible, 500);

  return (
    <animated.span ref={ref} style={fadeIn}>
      {children}
    </animated.span>
  );
};

AnimatedText.propTypes = {
  children: PropTypesAirBnb.or([PropTypes.string, PropTypes.element, PropTypes.array])
};
