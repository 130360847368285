import React from 'react';
import PropTypes from 'prop-types';

import styles from './style.module.scss';

import { useObserver } from '@utils/hooks/useObserver';

export const AnimatedSvg = ({ Icon }) => {
  const [ref, isVisible] = useObserver({ threshold: 0.5 });
  return (
    <div className={styles.wrapper} ref={ref}>
      <Icon className={isVisible ? styles.icon : ''} />
    </div>
  );
};

AnimatedSvg.propTypes = {
  Icon: PropTypes.func.isRequired
};
