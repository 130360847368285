import React from 'react';
import { animated } from 'react-spring';
import PropTypes from 'prop-types';

import { useObserver } from '@utils/hooks/useObserver';
import { useMobileAppearTop } from '@utils/hooks/useAnimation';

import styles from './style.module.scss';

export const HeaderTitle = ({ children, color, marginBottom }) => {
  const [ref, isVisible] = useObserver({ threshold: 0.7 });

  const appearTop = useMobileAppearTop(isVisible, 0);

  return (
    <div className={styles.wrapper} ref={ref}>
      <animated.h1 className={styles.header} style={{ ...appearTop, color, marginBottom }}>
        {children}
      </animated.h1>
    </div>
  );
};

HeaderTitle.propTypes = {
  children: PropTypes.element.isRequired,
  color: PropTypes.string,
  marginBottom: PropTypes.string
};
