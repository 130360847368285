import React from 'react';
import PropTypes from 'prop-types';

import { ServiceItem } from '@components/Services/ServiceItem';
import { Background } from '@components/Background';
import { Slider } from '@components/Slider';
import { Wrapper } from '@components/Wrapper';
import { HeaderTitle } from '@components/HeaderTitle';
import { AnimatedText } from '@components/AnimatedText';

import styles from './style.module.scss';
import { services } from '@data';

export const Services = ({ isSm }) => {
  return (
    <Background bg="white">
      <Wrapper isHomePage>
        <div id="services">
          <div className={styles.titleWrapper}>
            <HeaderTitle color="#000">
              <span>SERVICES</span>
            </HeaderTitle>

            <div className={styles.titleDescription}>
              <AnimatedText>
                Going deep into the process, rediscovering details and communicating each step of the way,
                we feel better about demonstrating our expertise rather than describing it. Our toolbox contains:
              </AnimatedText>
            </div>
          </div>
          <div className={styles.itemsWrapper}>
            {isSm && (
              <Slider
                pagesCount={3}
                mobileOnly
                renderContent={item => <ServiceItem {...item} key={item.title} />}
                itemsPerPage={1}
                withLine
                slides={services}
              />
            )}

            {!isSm && services.map(service => <ServiceItem key={service.title} {...service} />)}
          </div>
        </div>
      </Wrapper>
    </Background>
  );
};

Services.propTypes = {
  isSm: PropTypes.bool
};
