import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactScrollWheelHandler from 'react-scroll-wheel-handler';

import { SEO } from '@components/SEO';
import { Intro } from '@components/Intro';
import { Services } from '@components/Services';
import { Stack } from '@components/Stack';
import { Why } from '@components/Why';
import { GetInTouch } from '@components/GetInTouch';
import { References } from '@components/References';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { PageContainer } from '@components/PageContainer';
import { COLORS } from '@constants';
import { shouldPreventScroll } from '@utils';

import Particles from 'react-particles-js';

import { screens } from '@constants';

import { useBreakpoints } from '@utils/hooks';

const Home = () => {
  const { isSm, isLg, isMd } = useBreakpoints();

  const [page, setPage] = useState(0);
  const [isMapShown, setIsMapShown] = useState(false);
  const [isScrollDisabled, setIsScrollDisabled] = useState(false);
  const [headerProps, setHeaderProps] = useState(screens[0].header);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(`%c current version is: ${VERSION}`, 'color: orange; font-size: 13px;');

    window.addEventListener('scroll', scroll);

    return () => {
      window.removeEventListener('scroll', scroll);
    };
  }, []);

  const scroll = () => {
    const isLg = window ? window.innerWidth > 1024 : true;
    if (isLg) return;

    const paddings = 56;
    const mdRefsMarginBottom = 64;
    const introHeight = document ? document.getElementById('home').getBoundingClientRect().height : window.outerHeight;
    const servicesHeight = document
      ? document.getElementById('services').getBoundingClientRect().height + paddings
      : window.outerHeight;
    const stackHeight = document
      ? document.getElementById('stack').getBoundingClientRect().height + paddings
      : window.outerHeight;
    const whyHeight = document
      ? document.getElementById('why').getBoundingClientRect().height + (isMd ? 0 : paddings)
      : window.outerHeight;
    const refsHeight = document
      ? document.getElementById('refs').getBoundingClientRect().height + (isMd ? mdRefsMarginBottom : paddings)
      : window.outerHeight;

    const heights = { introHeight, servicesHeight, stackHeight, whyHeight, refsHeight };

    handleScroll(heights);
  };

  const handleScroll = ({
    introHeight,
    servicesHeight,
    stackHeight,
    whyHeight,
    refsHeight
  }) => {
    if (window.scrollY < introHeight) {
      setHeaderProps(screens[0].smallHeader);
      return;
    }
    if (window.scrollY < introHeight + servicesHeight) {
      setHeaderProps(screens[1].smallHeader);
      return;
    }
    if (window.scrollY < introHeight + servicesHeight + stackHeight ) {
      setHeaderProps(screens[2].smallHeader);
      return;
    }

    if (window.scrollY < introHeight + servicesHeight + stackHeight * 2 + whyHeight) {
      setHeaderProps(screens[4].smallHeader);
      return;
    }
    if (
      window.scrollY <
      introHeight + servicesHeight + stackHeight * 2 + whyHeight + refsHeight
    ) {
      setHeaderProps(screens[5].smallHeader);
      return;
    }
  };

  const handleUp = event => {
    if (isScrollDisabled || shouldPreventScroll(event)) return;

    if (page > 0 && !isMapShown) {
      setPage(page - 1);
    }
    if (page === 6) {
      setIsMapShown(false);
    }
  };

  const handleDown = event => {
    if (isScrollDisabled || shouldPreventScroll(event)) return;

    if (page < 5) {
      setPage(page + 1);
    }
  };

  const resetPages = () => {
    setPage(0);
  };

  return (
    <PageContainer
      resetPages={resetPages}
      pages={isLg ? screens : undefined}
      headerProps={headerProps}
      currentPage={page}
      isMapShown={isMapShown}
    >
      <SEO title="Home" />

      <Particles
        style={{
          position: 'fixed',
          zIndex: -1,
          background: COLORS.black
        }}
      />

      {!isLg ? (
        <>
          <Intro page={0} />

          <Services page={1} isSm={isSm} />

          <Stack page={2} />

          <Why page={3} />

          <References page={4} />

          <GetInTouch page={5} />
        </>
      ) : (
        <ReactScrollWheelHandler
          upHandler={handleUp}
          downHandler={handleDown}
          customStyle={{
            width: '100%',
            height: '100vh',
            overflow: 'hidden'
          }}
          data-id="scrollHandler"
        >
          <Breadcrumbs currentPage={page} pages={screens} isMapShown={isMapShown} setPage={setPage} />

          {page === 0 ? <Intro page={page} /> : null}

          {page === 1 ? <Services page={page} isSm={isSm} isMd={isMd} /> : null}

          {page === 2 ? <Stack page={page} /> : null}

          {page === 3 ? <Why page={page} /> : null}

          {page === 4 ? <References page={page} /> : null}

          {page === 5 ? (
            <GetInTouch page={page} isMapShown={isMapShown} setIsScrollDisabled={setIsScrollDisabled} />
          ) : null}
        </ReactScrollWheelHandler>
      )}
    </PageContainer>
  );
};

Home.propTypes = {
  windowWidth: PropTypes.number,
  scroll: PropTypes.func
};

export default Home;
